<template>
<div class="InvoiceRecord">
  <div style="border-bottom: 10px solid #edeff2;">
    <div style="border-bottom: 1px solid #edeff2;">
      <div class="moTitle">发票信息</div>
    </div>
    <div class="accountManagement">
      <div></div>
      <InvoiceInformation :infoList="infoList"></InvoiceInformation>
    </div>
  </div>
  <div style="border-bottom: 1px solid #edeff2;">
    <div class="moTitle">发票记录</div>
  </div>
  <div class="table">
    <a-table :columns="invoice" :data-source="invoiceData"
             :pagination="false" :scroll="{ x: 1500 }">
      <span slot="invoiceMoney" slot-scope="text">
        ￥{{text}}
      </span>
      <span slot="invoiceStatus" slot-scope="text,record">
<!--        开票状态 0已申请 1开票中 3已开票 4已邮寄  -->
         <span v-if="record.invoiceMedia === '纸质发票'">
           <a-tag v-if="text === 0" color="#2db7f5">已申请</a-tag>
           <a-tag v-if="text === 1" color="red">开票中</a-tag>
           <a-tag v-if="text === 3" color="green">已开票</a-tag>
           <a-tag v-if="text === 4" color="green">已邮寄</a-tag>
         </span>
        <span v-else>
           <a-tag v-if="text === 0" color="#2db7f5">已申请</a-tag>
           <a-tag v-if="text === 1" color="red">开票中</a-tag>
           <a-tag v-if="text === 3 || text === 4" color="green">已完成</a-tag>
<!--           <a-tag v-if="text === 4" color="green">已邮寄</a-tag>-->
         </span>
      </span>
      <span slot="action" slot-scope="text,record">
        <a style="margin-right: 8px" @click="openDetails(record)">详情</a>
        <a v-if="record.electronicInvoice" @click="downInvoice(record)">下载电子发票</a>
        <span v-else style="color: #9c9b9b">下载电子发票</span>
      </span>
    </a-table>

    <div style="width: 100%;;margin-top: 10px" v-show="invoiceData.length">
      <div style="display: flex;justify-content: center">
        <a-pagination
            show-quick-jumper
            :current="page_no"
            :total="total"
            @change="paginationChange" />
      </div>
      <div style="height: 10px"></div>
    </div>
  </div>
  <a-drawer
      title="开票详情"
      placement="right"
      @close="close"
      :visible="BillingDetailsVisible"
      :get-container="false"
      width="800"
  >
    <div>
      <a-alert type="info" >
        <span slot="message">
           <div style="display: flex;" v-if="BillingDetails.invoiceStatus">
             <admin_con style="font-size: 25px;margin-bottom: 15px" type="icon-youxiang"/><div style="margin-top: 5px;margin-left: 5px">
             <span v-if="BillingDetails.invoiceMedia === '纸质发票'">
               <span v-if="BillingDetails.invoiceStatus === 1">申请开票</span>
               <span v-if="BillingDetails.invoiceStatus === 2">开票中</span>
               <span v-if="BillingDetails.invoiceStatus === 3">已开票</span>
               <span v-if="BillingDetails.invoiceStatus === 4">已邮寄</span>
             </span>
             <span v-if="BillingDetails.invoiceMedia === '电子发票'">
               <span v-if="BillingDetails.invoiceStatus === 1">申请开票</span>
               <span v-if="BillingDetails.invoiceStatus === 2">开票中</span>
               <span v-if="BillingDetails.invoiceStatus === 3 || BillingDetails.invoiceStatus ===4">已完成</span>
             </span>
           </div>
           </div>
           <a-steps size="small" :current="BillingDetails.invoiceStatus" style="margin-top: 10px">
             <a-step>
               <template slot="title">
                 申请开票
               </template>
               <span slot="description">{{BillingDetails.applyForInvoicingTime}}</span>
             </a-step>
             <a-step>
               <template slot="title">
                 开票中
               </template>
               <span slot="description">{{BillingDetails.confirmTime}}</span>
             </a-step>
             <a-step>
               <template slot="title">
                 已完成
               </template>
               <span slot="description">{{BillingDetails.invoiceTime}}</span>
             </a-step>
             <a-step v-if="BillingDetails.invoiceMedia !== '电子发票'">
               <template slot="title">
                 已邮寄
               </template>
               <span slot="description">{{BillingDetails.mailingTime}}</span>
             </a-step>
           </a-steps>
           <div v-if="BillingDetails.expressNumber" class="expressNumber">
             快递单号：{{BillingDetails.expressNumber}}
             <a-icon
                 @click="copyText('SF1389264770580')"
                 style="color:#45a5e6;
                  font-size: 14px;
                  margin-top: 3px;
                  margin-left: 6px;
                  cursor: pointer"
                 type="copy" />
             <a style="margin-left: 10px" @click="inquireLogisticsBtn(BillingDetails.expressNumber)">查询物流</a>
           </div>
        </span>
      </a-alert>
      <div>
        <div class="invoiceInformation">发票信息</div>
        <div class="invoiceInformation-content">
          <div class="info">
            <div class="contentFont">发票抬头：{{BillingDetails.invoiceHeader}}</div>
            <div class="contentFont">发票金额：<span style="color: red">{{BillingDetails.invoiceMoney}}元</span></div>
          </div>

          <div class="info">
            <div class="contentFont">发票类型：{{BillingDetails.invoiceType}}</div>
            <div class="contentFont">发票介质：{{BillingDetails.invoiceMedia}}</div>
          </div>

          <div class="info">
            <div class="contentFont">发票备注：{{BillingDetails.invoiceRemark}}</div>
            <div class="contentFont">发票内容：{{BillingDetails.invoiceServiceName}}</div>
          </div>

          <div class="info">
            <div class="contentFont">发票税率：{{BillingDetails.invoiceTaxRate}}%</div>
            <div class="contentFont">发票号码：{{BillingDetails.invoiceNumber}}</div>
          </div>
        </div>
      </div>

      <div>
        <div class="invoiceInformation">邮寄信息</div>
        <div v-if="BillingDetails.invoiceMedia === '纸质发票'" class="invoiceInformation-content">
          <div class="info">
            <div class="contentFont">收件人姓名：{{BillingDetails.addressName}}</div>
            <div class="contentFont">联系电话：{{BillingDetails.addressPhone}}</div>
          </div>

          <div class="info">
            <div class="contentFont">收件地址：{{BillingDetails.addressProvince}}{{BillingDetails.addressCity}}{{BillingDetails.addressDetail}}</div>
          </div>
        </div>
        <div v-else class="invoiceInformation-content">
          <div class="info">
            <div class="contentFont">电子邮箱：{{BillingDetails.email}}</div>
          </div>
        </div>
      </div>

      <div>
        <div class="invoiceInformation">订单信息</div>
        <a-table bordered :pagination="false" :columns="orderInfor" :data-source="BillingDetails.orderDetails">
          <span slot="orderMoney" slot-scope="text">￥{{text}}</span>
        </a-table>
      </div>
    </div>
  </a-drawer>
</div>
</template>

<script>
import {admin_icon} from "@/utils/myIcon";
import {getAuthenticationInformation, getInvoice} from "@/service/authentication";
import InvoiceInformation from "@/components/Material/InvoiceInformation";
const invoice = [
  {
    title: '发票抬头',
    dataIndex: 'invoiceHeader',
    key: 'invoiceHeader',
    scopedSlots: { customRender: 'invoiceHeader' },
  },{
    title: '抬头类型',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' },
  },{
    title: '发票类型',
    dataIndex: 'invoiceType',
    key: 'invoiceType',
    scopedSlots: { customRender: 'invoiceType' },
  },{
    title: '发票金额',
    dataIndex: 'invoiceMoney',
    key: 'invoiceMoney',
    scopedSlots: { customRender: 'invoiceMoney' },
  },{
    title: '开票介质',
    dataIndex: 'invoiceMedia',
    key: 'invoiceMedia',
    scopedSlots: { customRender: 'invoiceMedia' },
  },{
    title: '发票状态',
    dataIndex: 'invoiceStatus',
    key: 'invoiceStatus',
    scopedSlots: { customRender: 'invoiceStatus' },
  },{
    title: '申请时间',
    dataIndex: 'applyForInvoicingTime',
    key: 'applyForInvoicingTime',
    scopedSlots: { customRender: 'applyForInvoicingTime' },
  },,{
    title: '操作',
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  },
];
const invoiceData = [];

const orderInfor = [
  {
    title: '订单号',
    dataIndex: 'orderNumber',
    key: 'orderNumber',
  }, {
    title: '订购服务',
    dataIndex: 'serviceName',
    key: 'serviceName',
  }, {
    title: '实际金额',
    dataIndex: 'orderMoney',
    key: 'orderMoney',
    scopedSlots: { customRender: 'orderMoney' },
  }, {
    title: '支付时间',
    dataIndex: 'paymentTime',
    key: 'paymentTime',
  },
];
export default {
  name: "InvoiceRecord",
  components:{admin_con: admin_icon,InvoiceInformation},
  data(){
    return{
      infoList:{},
      invoice,
      invoiceData,
      BillingDetailsVisible:false,

      orderInfor,

      page_no:1,
      total:0,
      BillingDetails:{},
    }
  },
  created() {
    this.postInvoices();
    this.getInformation();
  },
  methods:{
    //获取基本信息
    async getInformation() {
      const response = await getAuthenticationInformation();
      if (response.code === 200) {
        this.infoList = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    openDetails(row){
      console.log(row)
      this.BillingDetails = row;
      this.BillingDetailsVisible = true;
    },
    downInvoice(row){
      window.open(row.electronicInvoice)
    },
    async postInvoices() {
      let data = {
        page_no:this.page_no,
        page_size:10,
      }
      const res = await getInvoice(data);
      if (res.code === 0) {
        this.invoiceData = res.data;
        this.total = res.count;
      } else {
        this.$message.warning(res.message);
      }
    },
    paginationChange(){

    },
    close(){
      this.BillingDetailsVisible = false;
    },
    copyText(appSrc) {
      //创建input标签
      var input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = appSrc;
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //成功提示信息
      this.$message.success('文本已复制到剪贴板!')
      //移除input标签
      document.body.removeChild(input)
    },
    inquireLogisticsBtn(number){
      window.open('https://www.baidu.com/s?wd='+number);
    }
  }
}
</script>

<style scoped lang="scss">
.InvoiceRecord{
  border: 10px solid #edeff2;
  .accountManagement {
    width: 98%;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .moTitle {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin: auto;
    height: 50px;
    line-height: 50px;
    font-weight: 600;
    color: $topTitleColor;
  }
  .table{
    width: 98%;
    margin: auto;
    margin-top: 20px;
      .rpa-table-header{
        overflow: hidden !important;;
        margin-bottom:auto!important;
      }
      ::-webkit-scrollbar {
        width:10px;
        height: 10px;
      }
      ::-webkit-scrollbar-thumb{
        background-color: #C1C1C1;
      }
      ::-webkit-scrollbar-track {
        background-color: #F1F1F1;
      }
  }
  .expressNumber{
    display: flex;justify-content: right;margin-top: 15px;font-size: 12px;
  }
  .invoiceInformation{
    height: 50px;
    line-height: 50px;
    padding-left: 10px;
    margin-top: 20px;
    border: 1px solid #dbdcde;
    color: #333333;
    font-weight: bold;
  }
  .invoiceInformation-content{
    //height: 200px;
    border: 1px solid #dbdcde;
    padding: 20px;
    color: #333333;
    font-size: 12px;
    .info{
      display: flex;justify-content: space-between;
      margin-bottom: 20px;
      .contentFont{
        width: 200px;
      }
    }
  }
}
</style>
